// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/home/mark/src/europalabs/websites/devshedscom/packages/landing-gatsby/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/home/mark/src/europalabs/websites/devshedscom/packages/landing-gatsby/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-eula-js": () => import("/home/mark/src/europalabs/websites/devshedscom/packages/landing-gatsby/src/pages/eula.js" /* webpackChunkName: "component---src-pages-eula-js" */),
  "component---src-pages-index-js": () => import("/home/mark/src/europalabs/websites/devshedscom/packages/landing-gatsby/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("/home/mark/src/europalabs/websites/devshedscom/packages/landing-gatsby/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-sla-js": () => import("/home/mark/src/europalabs/websites/devshedscom/packages/landing-gatsby/src/pages/sla.js" /* webpackChunkName: "component---src-pages-sla-js" */),
  "component---src-pages-support-js": () => import("/home/mark/src/europalabs/websites/devshedscom/packages/landing-gatsby/src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-tech-js": () => import("/home/mark/src/europalabs/websites/devshedscom/packages/landing-gatsby/src/pages/tech.js" /* webpackChunkName: "component---src-pages-tech-js" */)
}

